import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/code/caB8",
    // component: Home,
  },
  {
    //二维码
    path: "/codetest",
    name: "CodeTest",
    component: () => import("../views/CodeTest.vue"),
    meta: { title: "二维码" },
  },
  {
    //去下载
    path: "/code/:key",
    name: "Code",
    component: () => import("../views/Code.vue"),
    meta: { title: "Go to download" },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
