import Vue from "vue";
import VueI18 from "vue-i18n";
import en from "./en";
import zh from "./zh";
Vue.use(VueI18);

const i18n = new VueI18({
  locale: "en",
  messages: {
    zh: zh,
    en: en,
  },
  silentTranslationWarn: true,
});
export default i18n;
