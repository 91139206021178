import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "@/styles/index.scss"; // global css
import i18n from "./lang/index";
import moment from "moment";
import VueWechatTitle from "vue-wechat-title";
Vue.use(VueWechatTitle);

import Vuex from "vuex";
Vue.use(Vuex);

// 按需导入
import {
  Tab,
  Tabs,
  Button,
  NavBar,
  Search,
  Cell,
  CellGroup,
  Popup,
  ActionSheet,
  Uploader,
  Icon,
  Field,
  Toast,
  Badge,
  ImagePreview,
  Image,
  Loading,
  PullRefresh,
} from "vant";
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Search);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(ActionSheet);
Vue.use(Uploader);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Badge);
Vue.use(ImagePreview);
Vue.use(Image);
Vue.use(Loading);
Vue.use(PullRefresh);
Vue.config.productionTip = false;

// 挂载到原型
Vue.prototype.$moment = moment;

// vuex
const store = new Vuex.Store({
  state: {
    timeStamp: 0, //时间戳
    logUrl: "", //日志url
    refreshTimeStamp: 0,
  },
  mutations: {
    timerUpdate(state, data) {
      state.timeStamp = data;
    },
    refreshTimeStamp(state, data) {
      state.refreshTimeStamp = data;
    },
    uploadSucceededUrl(state, url) {
      state.logUrl = url;
    },
  },
  getters: {},
});

new Vue({
  router,
  i18n, //注入
  store,
  render: (h) => h(App),
}).$mount("#app");

// app 调用js的方法

// 日志 上传成功
window.uploadSucceeded = (url) => {
  store.commit("uploadSucceededUrl", url);
  store.commit("timerUpdate", new Date().getTime());
};
