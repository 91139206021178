export default {
  commonProblem: {
    title: "Help",
    classify: "Classification",
    commonProblem: "Common Problem",
    search: "Search",
  },
  feedback: {
    title: "Feedback or Suggestion",
    createFeedback: "I Want Feedback",
    tell: "Tell us your Questions or Suggestions",
    feedbackType: "Please select the type of feedback",
    feedbackTypeTitle: "Types of Feedback",
    submit: "Submit",
    send: "Send",
    userNamePlaceholder: "enter one user name",
    inputContentPlaceholder: "Please enter your content",
    feedbackContentPlaceholder: "Please enter the feedback content",
    feedbackTypePlaceholder: "Please select the type of feedback",
    createdSuccessfully: "Created successfully!",
  },
  feedbackType: [
    {
      value: 1,
      label: "Connection synchronization",
      isActive: false,
    },
    {
      value: 2,
      label: "Data exception",
      isActive: false,
    },
    {
      value: 3,
      label: "Abnormal function",
      isActive: false,
    },
    {
      value: 4,
      label: "equipment failure",
      isActive: false,
    },
    {
      value: 5,
      label: "propose",
      isActive: false,
    },
    {
      value: 6,
      label: "other",
      isActive: false,
    },
  ],
};
