export default {
  commonProblem: {
    title: "帮助",
    classify: "分类",
    commonProblem: "常见问题",
    search: "搜索",
  },
  feedback: {
    title: "反馈或建议",
    createFeedback: "我想反馈",
    tell: "告诉我们您的问题或建议",
    feedbackType: "请选择反馈类型",
    feedbackTypeTitle: "反馈类型",
    submit: "提交",
    send: "发送",
    userNamePlaceholder: "请输入用户名",
    inputContentPlaceholder: "请输入您的内容",
    feedbackContentPlaceholder: "请输入反馈的内容",
    feedbackTypePlaceholder: "请选择反馈的类型",
    createdSuccessfully: "创建成功!",
  },
  feedbackType: [
    {
      value: 1,
      label: "连接同步",
      isActive: false,
    },
    {
      value: 2,
      label: "数据异常",
      isActive: false,
    },
    {
      value: 3,
      label: "功能异常",
      isActive: false,
    },
    {
      value: 4,
      label: "设备故障",
      isActive: false,
    },
    {
      value: 5,
      label: "建议",
      isActive: false,
    },
    {
      value: 6,
      label: "其他",
      isActive: false,
    },
  ],
};
